import React from 'react';
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { withStyles } from "@material-ui/core/styles";
import { green, red, yellow, lime, orange, grey } from "@material-ui/core/colors";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Typography, useMediaQuery } from "@material-ui/core";
import theme from 'src/theme';
import { makeStyles } from '@material-ui/core/styles';
import label from 'src/utils/label';
import { labels } from 'src/labels';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    label: {
        alignItems: 'left',
        justifyContent: 'left',
        flexGrow: 0,
        flexShrink: 0,
        lineHeight: '15px',
        fontSize: '12px'
    },
    rightmargin: { marginRight: '5px' },
    newButton: {
        "&.MuiToggleButtonGroup-groupedHorizontal": {
            borderRadius: '3px',
            borderLeft: '1px solid transparent',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        }
    },
    newButton_v1: {
        "&.MuiToggleButtonGroup-groupedHorizontal": {
            borderRadius: '3px',
            borderLeft: '1px solid transparent',
            marginRight: '5px',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
        }
    },
    newButton_v2: {
        "&.MuiToggleButtonGroup-groupedHorizontal": {
            borderRadius: '3px',
            borderLeft: '1px solid transparent',
            marginRight: '5px',
        }
    }
}));

const GreenToggleButton10 = withStyles({
    root: {
        color: "white",
        border: 0,
        background: green[800],
        "&:hover": {
            backgroundColor: green[700]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    },

})((props) => <ToggleButton {...props} />);

const GreenToggleButton9 = withStyles({
    root: {
        color: "white",
        border: 0,
        background: green[600],
        "&:hover": {
            backgroundColor: green[400]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    }
})((props) => <ToggleButton {...props} />);

const LimeToggleButton8 = withStyles({
    root: {
        color: "white",
        border: 0,
        background: lime[600],
        "&:hover": {
            backgroundColor: lime[500]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    }
})((props) => <ToggleButton {...props} />);

const LimeToggleButton7 = withStyles({
    root: {
        color: "white",
        border: 0,
        background: lime[400],
        "&:hover": {
            backgroundColor: lime[300]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    }
})((props) => <ToggleButton {...props} />);

const LimeToggleButton6 = withStyles({
    root: {
        color: "white",
        border: 0,
        background: lime[300],
        "&:hover": {
            backgroundColor: lime[200]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    }
})((props) => <ToggleButton {...props} />);

const YellowToggleButton6 = withStyles({
    root: {
        color: "white",
        border: 0,
        background: yellow[600],
        "&:hover": {
            backgroundColor: yellow[500]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    }
})((props) => <ToggleButton {...props} />);

const YellowToggleButton5 = withStyles({
    root: {
        color: "white",
        border: 0,
        background: yellow[800],
        "&:hover": {
            backgroundColor: yellow[700]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    }
})((props) => <ToggleButton {...props} />);

const OrangeToggleButton4 = withStyles({
    root: {
        color: "white",
        border: 0,
        background: orange[600],
        "&:hover": {
            backgroundColor: orange[500]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    }
})((props) => <ToggleButton {...props} />);

const OrangeToggleButton3 = withStyles({
    root: {
        color: "white",
        border: 0,
        background: orange[800],
        "&:hover": {
            backgroundColor: orange[700]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    }
})((props) => <ToggleButton {...props} />);

const RedToggleButton2 = withStyles({
    root: {
        color: "white",
        border: 0,
        background: red[400],
        "&:hover": {
            backgroundColor: red[300]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    }
})((props) => <ToggleButton {...props} />);

const RedToggleButton1 = withStyles({
    root: {
        color: "white",
        border: 0,
        background: red[600],
        "&:hover": {
            backgroundColor: red[500]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    }
})((props) => <ToggleButton {...props} />);

const RedToggleButton0 = withStyles({
    root: {
        color: "white",
        border: 0,
        background: red[800],
        "&:hover": {
            backgroundColor: red[700]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    }
})((props) => <ToggleButton {...props} />);

const RedToggleButton = withStyles({
    root: {
        color: "white",
        border: 0,
        background: red[900],
        "&:hover": {
            backgroundColor: red[800]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    }
})((props) => <ToggleButton {...props} />);

const GreyToggleButton = withStyles({
    root: {
        color: "white",
        border: 0,
        background: grey[500],
        "&:hover": {
            backgroundColor: grey[400]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    }
})((props) => <ToggleButton {...props} />);

const GreyToggleButton5 = withStyles({
    root: {
        color: "white",
        border: 0,
        background: grey[400],
        "&:hover": {
            backgroundColor: grey[100]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    }
})((props) => <ToggleButton {...props} />);

const LimeToggleButton = withStyles({
    root: {
        color: "white",
        border: 0,
        background: lime[600],
        "&:hover": {
            backgroundColor: lime[500]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    }
})((props) => <ToggleButton {...props} />);

const NPS = ({ ro, survey, isChecked, handleChange, questionId, answers }) => {
    const classes = useStyles();
    const [value, setNPSValue] = React.useState("");
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const handleNPSChange = (event, newValue) => {
        setNPSValue(newValue);
        handleChange(event, newValue, questionId, mobileDevice);
    };
    let newNPSDesignSurveys = [81,120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135,136,137,138,139]
    let improvementsQuestionFound = false, stronglyAgreeQuestionFound= false, importantQuestionFound = false, likelySurveyFound =false,
    importantList =[1493,1494,1495,1496,1497,1498,1499,1500,1501], satisfiedQuestionFound = false, satisfiedList =[1502,1503,1504,1505,1506,1507,1508,1509,1510], improvementsList = [924,935,943],
    likelySurveyList = [102,103,104,105,106,108,116];
    likelySurveyFound = likelySurveyList.some(id => id === survey);
    improvementsQuestionFound = improvementsList.some(id => id === questionId);
    importantQuestionFound = importantList.some(id => id === questionId);
    satisfiedQuestionFound = satisfiedList.some(id => id === questionId);
    let labelEnable = false;
    if(survey === 45 || survey === 60 || newNPSDesignSurveys.includes(survey)) {
        labelEnable = true;
    }
    if(survey === 76 || survey === 78) {
        stronglyAgreeQuestionFound = true;
    }
    let sortedAnswers = answers.sort((a, b) => (a.score < b.score) ? 1 : -1);
    const answersList = sortedAnswers.map((answer) => {
        var x = label(answer.label);
        var result;
        switch (x) {
            case '0':
                result = <RedToggleButton0 disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</RedToggleButton0>;
                break;
            case '1':
                result = <RedToggleButton1 disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</RedToggleButton1>;
                break;
            case '2':
                result = <RedToggleButton2 disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</RedToggleButton2>;
                break;
            case '3':
                result = <OrangeToggleButton3 disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</OrangeToggleButton3>;
                break;
            case '4':
                result = <OrangeToggleButton4 disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</OrangeToggleButton4>;
                break;
            case '5':
                result = <YellowToggleButton5 disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</YellowToggleButton5>;
                break;
            case '6':
                result = <YellowToggleButton6 disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</YellowToggleButton6>;
                break;
            case '7':
                result = <LimeToggleButton7 disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</LimeToggleButton7>;
                break;
            case '8':
                result = <LimeToggleButton8 disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</LimeToggleButton8>;
                break;
            case '9':
                result = <GreenToggleButton9 disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</GreenToggleButton9>;
                break;
            case '10':
                result = <GreenToggleButton10 disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</GreenToggleButton10>;
                break;
            default:
                result = null;
                break;
        }
        return (result)
    });

    const answersList_v1 = sortedAnswers.map((answer) => {
        var x = label(answer.label);
        var result;
        switch (x) {
            case '0':
                result = <RedToggleButton0 disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</RedToggleButton0>;
                break;
            case '1':
                result = <RedToggleButton0 disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</RedToggleButton0>;
                break;
            case '2':
                result = <RedToggleButton className={classes.rightmargin}  disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</RedToggleButton>;
                break;
            case '3':
                result = <RedToggleButton disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</RedToggleButton>;
                break;
            case '4':
                result = <RedToggleButton disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</RedToggleButton>;
                break;
            case '5':
                result = <GreyToggleButton className={classes.rightmargin} disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</GreyToggleButton>;
                break;
            case '6':
                result = <LimeToggleButton className={classes.rightmargin} disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</LimeToggleButton>;
                break;
            case '7':
                result = <LimeToggleButton disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</LimeToggleButton>;
                break;
            case '8':
                result = <LimeToggleButton disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</LimeToggleButton>;
                break;
            case '9':
                result = <GreenToggleButton10 className={classes.rightmargin} disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</GreenToggleButton10>;
                break;
            case '10':
                result = <GreenToggleButton10 disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</GreenToggleButton10>;
                break;
            default:
                result = null;
                break;
        }
        return (result)
    });

    const answersList_v2 = sortedAnswers.map((answer) => {
        var x = label(answer.label);
        var result;
        switch (x) {
            case '0':
                result = <RedToggleButton0 disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</RedToggleButton0>;
                break;
            case '1':
                result = <RedToggleButton1 className={classes.newButton} disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</RedToggleButton1>;
                break;
            case '2':
                result = <RedToggleButton2 className={classes.newButton_v1}  disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</RedToggleButton2>;
                break;
            case '3':
                result = <OrangeToggleButton3 disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</OrangeToggleButton3>;
                break;
            case '4':
                result = <OrangeToggleButton4 className={classes.newButton} disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</OrangeToggleButton4>;
                break;
            case '5':
                result = <GreyToggleButton5 className={classes.newButton_v2} disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</GreyToggleButton5>;
                break;
            case '6':
                result = <LimeToggleButton6 className={classes.newButton_v1} disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</LimeToggleButton6>;
                break;
            case '7':
                result = <LimeToggleButton7 disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</LimeToggleButton7>;
                break;
            case '8':
                result = <LimeToggleButton8 className={classes.newButton} disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</LimeToggleButton8>;
                break;
            case '9':
                result = <GreenToggleButton9 className={classes.newButton_v1} disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</GreenToggleButton9>;
                break;
            case '10':
                result = <GreenToggleButton10 disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</GreenToggleButton10>;
                break;
            default:
                result = null;
                break;
        }
        return (result)
    });
    let dir = localStorage.getItem('dir');
    return (
        <div className={classes.root}>
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                <Grid container spacing={1}>
                {(mobileDevice && newNPSDesignSurveys.includes(survey)) && <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={3}>
                            <Typography className={classes.label}>
                                {label(labels.get("highly_agree"))}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography style={{margin: (dir ==='ltr') ? '0 0 0 15px' : '0 15px 0 0px'}} className={classes.label} >
                                {label(labels.get("agree"))}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography style={{margin: (dir ==='ltr') ? '0 0 0 -13px' : '0 -6px 0 0'}} className={classes.label} >
                                {label(labels.get("neutral"))}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography style={{margin: (dir ==='ltr') ? '0 0 0 -12px' : '0 0 0 0'}} className={classes.label} >
                                {label(labels.get("disagree"))}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.label}>
                                {label(labels.get("highly_disagree"))}
                            </Typography>
                        </Grid>
                    </Grid>
                    </Grid>}
                    <Grid item xs={12}>
                        <ToggleButtonGroup size={mobileDevice ? 'medium' : 'large'} value={value} exclusive onChange={handleNPSChange}>
                            {(survey === 76 || (mobileDevice && survey === 78)) ? answersList_v1 : (mobileDevice && newNPSDesignSurveys.includes(survey) ) ? answersList_v2 : answersList}
                        </ToggleButtonGroup>
                    </Grid>
                    {!labelEnable && <> <Grid item xs={stronglyAgreeQuestionFound? 5 : mobileDevice ? 3 : 2}>
                        <Typography className={classes.label}>
                            {likelySurveyFound ? label(labels.get("highly_likely")) : (stronglyAgreeQuestionFound) ?  label(labels.get("strongly_agree")) : (improvementsQuestionFound) ?  label(labels.get("no_need_improvements")) : (importantQuestionFound) ? label(labels.get("highly_important")) : (satisfiedQuestionFound) ? label(labels.get("highly_satisfied")) : label(labels.get("extremely_likely"))}
                        </Typography>
                    </Grid>
                    <Grid item xs={stronglyAgreeQuestionFound? 5 : mobileDevice ? 7 : 3}>
                        <Typography className={classes.label} >
                            {(improvementsQuestionFound || importantQuestionFound || satisfiedQuestionFound || stronglyAgreeQuestionFound || likelySurveyFound) ? ' ' :  label(labels.get("neutral"))}
                        </Typography>
                    </Grid>
                    <Grid item xs={stronglyAgreeQuestionFound? 2 : mobileDevice ? 2 : 7}>
                        <Typography className={classes.label}>
                            {likelySurveyFound ? label(labels.get("highly_unlikely")) : (stronglyAgreeQuestionFound) ?  label(labels.get("strongly_disagree")) : (improvementsQuestionFound) ? label(labels.get("need_improvements")) : (importantQuestionFound) ? label(labels.get("not_at_all_important")) : (satisfiedQuestionFound) ? label(labels.get("highly_dissatisfied")) : label(labels.get("not_at_all_likely"))}
                        </Typography>
                    </Grid></>}
                </Grid>
            </Box>
        </div>
    );
}

export default NPS;