import React from 'react';
import theme from 'src/theme';
import { makeStyles } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import { Typography, useMediaQuery } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from "@material-ui/core/styles";
import { green, red, lime, orange, grey } from "@material-ui/core/colors";
import label from 'src/utils/label';
import { labels } from 'src/labels';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        // width: 1600
    },
    group: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
    },
    label: {
        alignItems: 'left',
        justifyContent: 'left',
        flexGrow: 0,
        flexShrink: 0,
        lineHeight: '15px',
        fontSize: '13px',
        paddingBottom: '5px'
    },
    numbers: {
        textAlign: 'center',
        fontSize: '14px',
        boxSizing: 'border-box',
        lineHeight: '40px',
        borderRadius: '4px',
        width: '30px', height:'45px', 
        border: '1px solid transparent', 
        color: 'White'
    }
});

let GreenRadio = withStyles({
    root: {
        color: green[400],
        "&$checked": {
            color: green[600]
        },
    },
    checked: {}
})((props) => <Radio color="default" {...props} />);

const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      "&$checked": {
        color: green[600]
      },
      '& label.Mui-focused': {
        color: green[600],
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: green[400],
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: green[400],
        },
        '&:hover fieldset': {
          borderColor: green[400],
        },
        '&.Mui-focused fieldset': {
          borderColor: green[600],
        }
      }
    },
    checked: {}
  })((props) => <Checkbox color="default" {...props} />);

const MatrixNPS = ({ ro, questionsType, handleSubmit, surveyQuestions, handleNext, isChecked, matrixQuestions, survey, handleChange, questionId, answers}) => {
    const classes = useStyles();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const handleMatrixChange = (event, newValue, qId) => {
        let index = surveyQuestions.findIndex(sq => sq.id === qId)
        let isCompleted = ((index === matrixQuestions.length) && (surveyQuestions.length - matrixQuestions.length === 2)) ? true : false;
        handleChange(event, newValue, qId);
        handleSubmit(qId, isCompleted, true);
    };
    const getColor = (ans) => {
        var x = label(ans.label);
        var result;
        switch (x) {
            case '0':
                result = red[800];
                break;
            case '1':
                result = red[600];
                break;
            case '2':
                result = red[400]
                break;
            case '3':
                result = orange[800]
                break;
            case '4':
                result = orange[600]
                break;
            case '5':
                result = grey[300]
                break;
            case '6':
                result = lime[300]
                break;
            case '7':
                result = lime[400]
                break;
            case '8':
                result = lime[500]
                break;
            case '9':
                result = green[500]
                break;
            case '10':
                result = green[700]
                break;
            default:
                result = 'black';
                break;
        }
        return (result)
    };
    let dir = localStorage.getItem('dir');
    return (
        <div className={classes.root} >
            {matrixQuestions.map(question => {
                question.answers.sort((a, b) => (a.score < b.score) ? 1 : -1);
                let operator = ([7090,5739,5751,5639,5651,5689,5701,5789,5801,5839,5851,5889,5901,5939,5951,5989,6001,6039,6051,6089,6101,6139,6151,6189,6201,6239,6251,6289,6301,6339,6351,6389,6401].includes(question.id) || question.id === 5589 || question.id === 5601 || question.id === 5539 || question.id === 5551 || question.id === 5489 || question.id === 5501 || question.id === 5439 || question.id === 5451 || question.id === 2513 || question.id === 2525 || question.id === 1030 || question.id === 1043 || question.id === 2115 || question.id === 2126 ) ? ':' : '  ';
                return (
                <Box key={question.id} p={3} display="flex" alignItems="center" justifyContent="center" flexDirection={mobileDevice ? 'column' :'row' }>
                    <Grid container spacing={3} direction={mobileDevice ? 'column' :'row' }>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                {([7090,5739,5751,5639,5651,5689,5701,5789,5801,5839,5851,5889,5901,5939,5951,5989,6001,6039,6051,6089,6101,6139,6151,6189,6201,6239,6251,6289,6301,6339,6351,6389,6401].includes(question.id) || question.id === 5589 || question.id === 5601 || question.id === 5539 || question.id === 5551 || question.id === 5489 || question.id === 5501 || question.id === 5439 || question.id === 5451 || question.id === 2513 || question.id === 2525 || question.id === 1030 || question.id === 1043 || question.id === 1493 || question.id === 1502) && 
                                    <Typography variant="h5">
                                        {label(question.label).split(operator).length > 1 ? label(question.label).split(operator)[0] : label(question.label)}
                                    </Typography>
                                }
                                </Grid>
                                <Grid item xs={6}>
                                    {([7090,5739,5751,5639,5651,5689,5701,5789,5801,5839,5851,5889,5901,5939,5951,5989,6001,6039,6051,6089,6101,6139,6151,6189,6201,6239,6251,6289,6301,6339,6351,6389,6401].includes(question.id) || question.id === 5589 || question.id === 5601 || question.id === 5539 || question.id === 5551 || question.id === 5489 || question.id === 5501 || question.id === 5439 || question.id === 5451 || question.id === 2513 || question.id === 2525 ) && <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <Typography className={classes.label}>
                                                {label(labels.get("highly_agree"))}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography style={{margin: (dir ==='ltr') ? '0 0 0 -4px' : '0 0 0 0'}} className={classes.label} >
                                                {label(labels.get("agree"))}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography style={{margin: (dir ==='ltr') ? '0 0 0 -12px' : '0 0 0 0'}} className={classes.label} >
                                                {label(labels.get("neutral"))}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography style={{margin: (dir ==='ltr') ? '0 0 0 -12px' : '0 -5px 0 0'}} className={classes.label} >
                                                {label(labels.get("disagree"))}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography className={classes.label}>
                                                {label(labels.get("highly_disagree"))}
                                            </Typography>
                                        </Grid>
                                    </Grid>}
                                    <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 11, md: 11 }}>
                                        {([7090,5739,5751,5639,5651,5689,5701,5789,5801,5839,5851,5889,5901,5939,5951,5989,6001,6039,6051,6089,6101,6139,6151,6189,6201,6239,6251,6289,6301,6339,6351,6389,6401].includes(question.id) || question.id === 5589 || question.id === 5601 || question.id === 5539 || question.id === 5551 || question.id === 5489 || question.id === 5501 || question.id === 5439 || question.id === 5451 || question.id === 2513 || question.id === 2525 || question.id === 1030 || question.id === 1043 || question.id === 1493 || question.id === 1502 || question.id === 2115 || question.id === 2126) && question.answers.map((answer, index) => {
                                            return (
                                            <Grid item xs={1} sm={1} md={1} key={index}>
                                                <Typography variant="h5" className={classes.numbers} style={{ backgroundColor: getColor(answer)}}>
                                                    {label(answer.label)}
                                                </Typography>
                                            </Grid>)
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={mobileDevice ? 12 :6 }>
                            <Typography variant="subtitle2">
                                {label(question.label).split(operator).length > 1 ? label(question.label).split(operator)[1] : label(question.label)}
                            </Typography>
                        </Grid>
                        <Grid item xs={mobileDevice ? 12 :6 }>
                            <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, sm: 11, md: 11 }}>
                                {question.answers.map((answer, index) => {
                                    return (
                                    <Grid item xs={1} sm={1} md={1} key={index}>
                                        <FormControlLabel
                                            key={answer.id}
                                            value={answer.questionId + '_' + answer.id}
                                            control={<GreenRadio size="medium" />}
                                            disabled={JSON.parse(ro)}
                                            onChange={(e) => handleMatrixChange(e, e.value, answer.questionId)}
                                            checked={isChecked(answer.questionId, answer.id)}
                                        />
                                    </Grid>)
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                )
            })}
        </div>
    );
};

const TableRadio = ({ ro, isComments, getComments, surveyQuestions, questionsType, handleSubmit, handleNext, isChecked, matrixQuestions, survey, handleChange, questionId, answers}) => {
    const classes = useStyles();
    GreenRadio = withStyles({
        root: {
            color: survey === 118 ? 'blue' : survey === 119 ? '#ffaa01' : green[400],
            "&$checked": {
                color: survey === 118 ? 'blue' : survey === 119 ? '#ffaa01' : green[600]
            },
        },
        checked: {}
    })((props) => <Radio color="default" {...props} />);

    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const handleMatrixChange = (event, newValue, qId) => {
        let index = surveyQuestions.findIndex(sq => sq.id === qId)
        let isCompleted = ((index === matrixQuestions.length) && (surveyQuestions.length - matrixQuestions.length === 2)) ? true : false;
        handleChange(event, newValue, qId)
        handleSubmit(qId, isCompleted, true);
    };
    return (
        <div className={classes.root} >
            {matrixQuestions.map(question => {
                question.answers.sort((a, b) => (a.score > b.score) ? 1 : -1);
                let operator = (question.id === 3333 || question.id === 4909 || question.id === 4925 || question.id === 6443) ? '.' : (question.id === 7063 || question.id === 5129) ? ':' : '?';
                return (
                <Box key={question.id} p={3} display="flex" alignItems="center" justifyContent="center" flexDirection={mobileDevice ? 'column' :'row' }>
                    <Grid container direction={mobileDevice ? 'column' :'row' }>
                        <Grid item xs={((question.surveyId === 85 && question.id < 3230) || (question.surveyId === 94 || question.surveyId === 115 || question.surveyId === 114 || question.surveyId === 117 || question.surveyId === 118 || question.surveyId === 119 || question.surveyId === 140 || question.surveyId === 153 )) ? 4 : (question.surveyId === 85) ? 2 : 6}>
                        {(question.id ===  7142 || question.id === 7120 ||question.id === 7063 || question.id === 7044 || question.id === 5382 || question.id === 5391 || question.id === 5399 || question.id === 6443 || question.id === 5416 || question.id === 5424 || question.id === 5345 || question.id === 5354 || question.id === 5362 || question.id === 5289 || question.id === 5304 || question.id === 5309 || question.id === 5190 || question.id === 5184 || question.id === 5168 || question.id === 5129 || question.id === 5123 || question.id === 5109 || question.id === 5005 || question.id === 3333 || question.id === 3157 || question.id === 4909 || question.id === 4925) && 
                            <Typography variant="h5" style={{paddingBottom: (question.surveyId === 153 || question.surveyId === 152 || question.surveyId === 140 || question.surveyId === 153 || question.surveyId === 119 || question.surveyId === 118 || question.surveyId === 117 || question.surveyId === 115 || question.surveyId === 114 || question.surveyId === 109 || question.surveyId === 86 || question.surveyId === 97 || question.surveyId === 98) ? '35px' : 0, fontSize: (question.surveyId === 85 || question.surveyId === 86 || question.surveyId === 97 || question.surveyId === 98 || question.surveyId === 115 || question.surveyId === 114 || question.surveyId === 140 || question.surveyId === 153) ? '14px' : '16px'}}>
                                {label(question.label).split(operator).length > 1 ? label(question.label).split(operator)[0] : label(question.label)}
                            </Typography>
                        }
                        </Grid>
                        <Grid item xs={((question.surveyId === 85 && question.id < 3230) || question.surveyId === 94 || question.surveyId === 115 || question.surveyId === 114 || question.surveyId === 117 || question.surveyId === 118 || question.surveyId === 119 || question.surveyId === 140 || question.surveyId === 153) ? 8 : (question.surveyId === 85) ? 10 : 6}>
                            <Grid container spacing={1}>
                            {(question.id ===  7142 || question.id === 7120 ||question.id === 7063 || question.id === 7044 || question.id === 5382 || question.id === 5391 || question.id === 5399 || question.id === 6443 || question.id === 5416 || question.id === 5424 || question.id === 5345 || question.id === 5354 || question.id === 5362 || question.id === 5289 || question.id === 5304 || question.id === 5309 || question.id === 5190 || question.id === 5184 || question.id === 5168 || question.id === 5129 || question.id === 5123 || question.id === 5109 || question.id === 5012 || question.id === 5005 || question.id === 4925 || question.id === 4909 || question.id === 4611 || question.id === 4639 || question.id === 4661 || question.id === 4705 || question.id === 4762 || question.id === 4776 || question.id === 4792|| question.id === 4816 || question.id === 3333 || question.id === 3157 || question.id === 3230 || question.id === 3251 || question.id === 3650 || question.id === 3663 || question.id === 3676 || question.id === 3689 || question.id === 3702 || question.id === 3774)
                                    && question.answers.map((answer, index) => {
                                    return (
                                    <Grid item md={((question.id >= 4705 && question.id <= 4748) || (question.surveyId === 152) || (question.surveyId === 150) || (question.surveyId === 149) || (question.id >= 4776 && question.id <= 4789) || (question.id >= 5005 && question.id <= 5020) || question.surveyId === 115 || question.surveyId === 114 || question.surveyId === 117 || question.surveyId === 118 || question.surveyId === 119 || question.surveyId === 140 || question.surveyId === 153) ? 2 : 1} key={index}>
                                        <Typography variant="h5" style={{paddingBottom: (question.surveyId === 115 || question.surveyId === 114 || question.surveyId === 94 || question.surveyId === 109) ? '25px' : 0, fontSize: (question.surveyId === 85 || question.surveyId === 88 || question.surveyId === 94 || question.surveyId === 109|| question.surveyId === 115 || question.surveyId === 114) ? '14px' : '16px'}}>
                                            {label(answer.label)}
                                        </Typography>
                                    </Grid>)
                                })}
                            </Grid>
                        </Grid>
                        <Grid item xs={((question.surveyId === 85 && question.id < 3230) || (question.surveyId === 94 || question.surveyId === 115 || question.surveyId === 114 || question.surveyId === 117 || question.surveyId === 118 || question.surveyId === 119 || question.surveyId === 140 || question.surveyId === 153)) ? 4 : (question.surveyId === 85) ? 2 : 6}>
                            <Typography variant="subtitle2">
                                {label(question.label).split(operator).length > 1 ? label(question.label).split(operator)[1] : label(question.label)}
                            </Typography>
                        </Grid>
                        <Grid item xs={((question.surveyId === 85 && question.id < 3230) || question.surveyId === 94 || question.surveyId === 115 || question.surveyId === 114 || question.surveyId === 117 || question.surveyId === 118 || question.surveyId === 119 || question.surveyId === 140 || question.surveyId === 153) ? 8 : (question.surveyId === 85) ? 10 : 6}>
                            <Grid container spacing={1}>
                                {question.answers.map((answer, index) => {
                                    return (
                                    <Grid item md={((question.id >= 4705 && question.id <= 4748) || (question.surveyId === 152) || (question.surveyId === 150) || (question.surveyId === 149) || (question.id >= 4776 && question.id <= 4789) || (question.id >= 5005 && question.id <= 5020) || question.surveyId === 115 || question.surveyId === 114 || question.surveyId === 117 || question.surveyId === 118 || question.surveyId === 119 || question.surveyId === 140 || question.surveyId === 153) ? 2 : 1} key={index}>
                                        <FormControlLabel
                                            key={answer.id}
                                            value={answer.questionId + '_' + answer.id}
                                            control={<GreenRadio size="medium" />}
                                            disabled={JSON.parse(ro)}
                                            onChange={(e) => handleMatrixChange(e, e.value, answer.questionId)}
                                            checked={isChecked(answer.questionId, answer.id)}
                                        />
                                    </Grid>)
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                )
            })}
        </div>
    );
};

const TableCheckbox = ({ ro, isComments, surveyQuestions, getComments, questionsType, handleSubmit, handleNext, isChecked, matrixQuestions, survey, handleChange, questionId, answers}) => {
    const classes = useStyles();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const handleMatrixChange = (event, newValue, qId) => {
        handleChange(event, newValue, qId);
        let index = surveyQuestions.findIndex(sq => sq.id === qId)
        let isCompleted = ((index === matrixQuestions.length) && (surveyQuestions.length - matrixQuestions.length === 2)) ? true : false;
        handleSubmit(qId, isCompleted, true);
    };
    let operator = '.';
    return (
        <div className={classes.root} >
            {matrixQuestions.map(question => {
                question.answers.sort((a, b) => (a.score > b.score) ? 1 : -1);
                return (
                <Box key={question.id} p={3} display="flex" alignItems="center" justifyContent="center" flexDirection={mobileDevice ? 'column' :'row' }>
                    <Grid container direction={mobileDevice ? 'column' :'row' }>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={(question.surveyId === 94)? 4 : 6}>
                                {/* {(question.id === 3333) && 
                                    <Typography variant="h5">
                                        {label(question.label).split(operator).length > 1 ? label(question.label).split(operator)[0] : label(question.label)}
                                    </Typography>
                                } */}
                                </Grid>
                                <Grid item xs={(question.surveyId === 94) ? 8 : 6}>
                                    <Grid container spacing={1}>
                                        {(question.id === 4639) && question.answers.map((answer, index) => {
                                            return (
                                            <Grid item md={((question.id >= 4639 && question.id <= 4657)) ? 2 : 1} key={index}>
                                                <Typography variant="h5" style={{paddingRight: (question.surveyId === 94) ? '25px' : 0, fontSize: (question.surveyId === 93) ? '14px' : '16px'}}>
                                                    {label(answer.label)}
                                                </Typography>
                                            </Grid>)
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={(question.surveyId === 94) ? 4 : 6}>
                            <Typography variant="subtitle2">
                                {label(question.label).split(operator).length > 1 ? label(question.label).split(operator)[1] : label(question.label)}
                            </Typography>
                        </Grid>
                        <Grid item xs={(question.surveyId === 94) ? 8 : 6}>
                            <Grid container spacing={4}>
                                {question.answers.map((answer, index) => {
                                    return (
                                    <Grid item md={((question.id >= 4639 && question.id <= 4657)) ? 2 : 1} key={index}>
                                        <FormControlLabel
                                            key={answer.id}
                                            value={answer.questionId + '_' + answer.id}
                                            control={<GreenCheckbox size="medium" />}
                                            disabled={JSON.parse(ro)}
                                            onChange={(e) => handleMatrixChange(e, e.value, answer.questionId)}
                                            checked={isChecked(answer.questionId, answer.id)}
                                        />
                                    </Grid>)
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                )
            })}
        </div>
    );
};

const MATRIX = ({ ro, isComments, getComments, questionsType, surveyQuestions, handleSubmit, matrixQuestions, isChecked, handleChange, survey, questionId, answers }) => {
    switch (questionsType) {
        case 'NPS': return MatrixNPS({ ro, questionsType, surveyQuestions, isChecked, matrixQuestions, handleSubmit, handleChange, survey, questionId, answers });
        case 'RADIO': return TableRadio({ ro, isComments, surveyQuestions, getComments, questionsType, isChecked, matrixQuestions, handleSubmit, handleChange, survey, questionId, answers });
        case 'CHECKBOX': return TableCheckbox({ ro, isComments, surveyQuestions, getComments, questionsType, isChecked, matrixQuestions, handleSubmit, handleChange, survey, questionId, answers });
        default:
            return null;
    }
}
export default MATRIX;