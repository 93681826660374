import React from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import theme from 'src/theme';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const rtlLangCodes = ["ar", "ur"];

const MainLayout = () => {
  const classes = useStyles();
  let { token } = useParams();
  console.log("token: " + token);
  let query = useQuery();
  let _lang = query.get("lang") ? query.get("lang") : query.get("l");
  let lang = _lang ? _lang : 'ar';
  let dir = rtlLangCodes.includes(lang) ? 'rtl' : 'ltr';
  localStorage.setItem("dir", dir);
  localStorage.setItem("lang", lang);
  document.querySelector("#root").setAttribute("dir", dir);
  theme.direction = dir;

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
