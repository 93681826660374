import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import label from 'src/utils/label';
import { labels } from 'src/labels';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const CustomizedSnackbar = props => {
    const { open, severity, msg } = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Snackbar open={open}>
                <Alert severity={severity}>
                    {msg}
                </Alert>
            </Snackbar>
        </div>
    );
}

CustomizedSnackbar.propTypes = {
    open: PropTypes.bool.isRequired,
    severity: PropTypes.string.isRequired,
    msg: PropTypes.string.isRequired
};

CustomizedSnackbar.defaultProps = {
    open: true,
    severity: 'info',
    msg: label(labels.get("loading"))

};

export default CustomizedSnackbar;

