import React from 'react';

const Logo = (props) => {
  return (
    <img
      alt="Logo"
      src={props.path ? props.path : "static/logo.png"}
      {...props}
      height= {props.height? props.height : '40em'}
    />
  );
};

export default Logo;
