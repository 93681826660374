import React from 'react';
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { withStyles } from "@material-ui/core/styles";
import { green, red, yellow, lime, orange } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography';
import { useMediaQuery } from "@material-ui/core";
import theme from 'src/theme';
import label from 'src/utils/label';


const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiToggleButton-root": {
            border: 0
        }
    }
}));

const RedSentimentVeryDissatisfiedIcon = withStyles({
    root: {
        color: red[600],
        fontSize: 30
    }
})((props) => <SentimentVeryDissatisfiedIcon {...props} />);

const OrangeSentimentDissatisfiedIcon = withStyles({
    root: {
        color: orange[600],
        fontSize: 30
    }
})((props) => <SentimentDissatisfiedIcon {...props} />);

const YellowSentimentSatisfiedIcon = withStyles({
    root: {
        color: yellow[600],
        fontSize: 30
    }
})((props) => <SentimentSatisfiedIcon {...props} />);

const LimeSentimentSatisfiedAltIcon = withStyles({
    root: {
        color: lime[600],
        fontSize: 30
    }
})((props) => <SentimentSatisfiedAltIcon {...props} />);

const GreenSentimentVerySatisfiedIcon = withStyles({
    root: {
        color: green[600],
        fontSize: 30
    }
})((props) => <SentimentVerySatisfiedIcon {...props} />);

const Smileys = ({ ro, isChecked, handleChange, questionId, answers }) => {
    const classes = useStyles();
    const [value, setSmileyValue] = React.useState("");
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const handleSmileyChange = (event, newValue) => {
        setSmileyValue(newValue);
        handleChange(event, newValue, questionId);
    };
    const getLabel = (value) => {
        let questionId = parseInt(value.split('_')[0]);
        let answerId = parseInt(value.split('_')[1]);
        const index = answers.findIndex((item => item.questionId === questionId && item.id === answerId));
        return (index >= 0) ? label(answers[index].label) : '';
    }

    const answersList = answers.map((answer, index) => {
        var result;
        switch (index) {
            case 4:
                result = <ToggleButton disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}><GreenSentimentVerySatisfiedIcon /></ToggleButton>;
                break;
            case 3:
                result = <ToggleButton disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}><LimeSentimentSatisfiedAltIcon /></ToggleButton>;
                break;
            case 2:
                result = <ToggleButton disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}><YellowSentimentSatisfiedIcon /></ToggleButton>;
                break;
            case 1:
                result = <ToggleButton disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}><OrangeSentimentDissatisfiedIcon /></ToggleButton>;
                break;
            case 0:
                result = <ToggleButton disabled={ro} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}><RedSentimentVeryDissatisfiedIcon /></ToggleButton>;
                break;
            default:
                result = null;
                break;

        }
        return (result)
    });
    return (
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <ToggleButtonGroup size={mobileDevice ? 'medium' : 'large'} className={classes.root} value={value} exclusive onChange={handleSmileyChange}>
                {answersList}
            </ToggleButtonGroup>
            {
                value !== null && value !== '' && (
                    <Box display="flex" p={2} alignItems="center" justifyContent="center">
                        <Typography variant="body1"> {getLabel(value)} </Typography>
                    </Box>
                )
            }
        </Box>
    );
}

export default Smileys;