import React from "react";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, ThemeProvider, jssPreset } from "@material-ui/core/styles";
import theme from 'src/theme';
import GlobalStyles from 'src/components/GlobalStyles';
// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });


function withRoot(Component) {
    function WithRoot(props) {
        return (
            <ThemeProvider theme={theme}>
                <StylesProvider jss={jss}>
                    <GlobalStyles />
                    <Component {...props} />
                </StylesProvider>
            </ThemeProvider>
        );
    }

    return WithRoot;
}

export default withRoot;
