const label = (label) => {
    let result = "";
    if (label === null) {
        console.log("label empty!");
        return result;
    }
    let labelMap = new Map(Object.entries(label));
    let lang = localStorage.getItem('lang');
    try {
        result = labelMap.get(lang);
    } catch (err) {
        console.log("label error!");
        console.log(err)
    }
    return result;
};
export default label;