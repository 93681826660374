import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import routes from 'src/routes';
import withRoot from 'src/withRoot';
import { withStyles } from "@material-ui/core/styles";
import { createStyles, makeStyles } from '@material-ui/core';

const styles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      backgroundColor: '#f4f6f8',
      height: '100%',
      width: '100%'
    },
    a: {
      textDecoration: 'none'
    },
    '#root': {
      height: '100%',
      width: '100%'
    }
  }
}));


const App = () => {
  const routing = useRoutes(routes, `${process.env.PUBLIC_URL}`);
  return (
    <>
      {routing}
    </>
  );
};

export default withRoot(
  withStyles(styles, { withTheme: true })(App)
);
